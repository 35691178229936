@import '~minireset.css/minireset';
@import "~bulma/css/bulma.css";
@import "~sweetalert2/dist/sweetalert2.css";
@import "~flatpickr/dist/flatpickr.css";

html,
body {
    height: 100%;
    overflow-y: hidden;
}

body {
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 15px;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

h1,
h2,
h3,
h4 {
    font-weight: bold;
    margin-bottom: 10px;
}

h2 {
    font-size: 21px;
}

h3 {
    font-size: 16px;
}

p + p {
    margin-top: 1rem;
}

img {
    display: block;
    vertical-align: middle;
}

.u-centered {
    margin-left: auto;
    margin-right: auto;
}

.u-w100 {
    width: 100%;
}
.u-h100 {
    height: 100%;
}

.modal-message {
    background-color: rgba(0, 0, 0, 0.65);

    .message {
        left: 5%;
        position: absolute;
        right: 5%;
        top: 50%;
        transform: translateY(-50%);
    }
}

.icon {
    display: inline-block;
    line-height: 1;

    &__svg {
        color: inherit;
        fill: currentColor;
        height: inherit;
        width: inherit;
    }

    &--20 {
        height: 20px;
        width: 20px;
    }

    &--32 {
        height: 32px;
        width: 32px;
    }

    &--40 {
        height: 40px;
        width: 40px;
    }
}

.lh1 {
    line-height: 1;
}

.m- {
    &-0 {
        margin: 0 !important;
    }

    &-15 {
        margin: 15px;
    }

    &b-- {
        &10 {
            margin-bottom: 10px;
        }
    }

    &l-- {
        &0 {
            margin-left: 0 !important;
        }

        &15 {
            margin-left: 15px;
        }
    }

    &r-- {
        &15 {
            margin-right: 15px;
        }
    }
}

.flex-- {
    &middle {
        align-items: center;
    }

    &between {
        justify-content: space-between;
    }
}

.is-grow {
    flex-grow: 1;
}

.buttons,
.buttons .button {
    margin-bottom: 0 !important;
}

.loading {
    width: 300px;
    height: 300px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

@import '_modal.scss';
@import '_bubble.scss';
@import '_loading.scss';
@import '_table.scss';
