//
// Modal
// --------------------------------------------------

// Variables
// ========================================================================
$modalBg: rgba(10, 10, 10, .76);
$modalBgLight: rgba(255, 255, 255, .9);

// Close
$modalCloseBg: rgba(10, 10, 10, .2);
$modalCloseAfterBeforeBg: #fff;
$modalCloseHoverFocusBg: rgba(10, 10, 10, .3);
$modalCloseActiveBg: rgba(10, 10, 10, .4);

// Card
$modalCardHeadFootBg: #f5f5f5;
$modalCardHeadBorder: #dbdbdb;
$modalCardTitleColor: #363636;
$modalCardTitleSize: 1.25rem;
$modalCardBodyBg: #fff;

$modalCardFootBorder: #dbdbdb;

// Placeholders
// ========================================================================

// Component
// ========================================================================
.modal {
    align-items: center;
    bottom: 0;
    display: none;
    justify-content: center;
    left: 0;
    overflow: hidden;
    position: absolute;
    right: 0;
    top: 0;
    z-index: 200;

    // Overlay
    &__background {
        background-color: $modalBg;
        bottom: 0;
        left: 0;
        position: absolute;
        right: 0;
        top: 0;

        &--light {
            background-color: $modalBgLight;
        }
    }

    &__react {
        align-items: center;
        display: flex;
        height: 100%;
    }

    &__card {
        margin: 0 20px;
        max-height: calc(100vh - 160px);
        overflow: auto;
        position: relative;
        width: 100%;
    }

    &__close {
        background-color: $modalCloseBg;
        border: 0;
        border-radius: 50%;
        cursor: pointer;
        display: inline-block;
        font-size: 1rem;
        height: 40px;
        outline: none;
        position: fixed;
        right: 20px;
        top: 20px;
        user-select: none;
        vertical-align: top;
        width: 40px;
        z-index: 201;

        &::before,
        &::after {
            background-color: $modalCloseAfterBeforeBg;
            content: "";
            display: block;
            left: 50%;
            position: absolute;
            top: 50%;
            transform: translateX(-50%) translateY(-50%) rotate(45deg);
            transform-origin: center center;
        }

        &::before {
            height: 2px;
            width: 50%;
        }

        &::after {
            height: 50%;
            width: 2px;
        }

        // State
        &:hover,
        &:focus {
            background-color: $modalCloseHoverFocusBg;
        }

        &:active {
            background-color: $modalCloseActiveBg;
        }
    }
}

.modal-card {
    display: flex;
    flex-direction: column;
    margin-left: .5em;
    margin-right: .5em;
    max-height: calc(100vh - 40px);
    overflow: hidden;
    transition: height .2s linear;
    width: 100%;

    &__head,
    &__foot {
        align-items: center;
        background-color: $modalCardHeadFootBg;
        display: flex;
        flex-shrink: 0;
        justify-content: flex-start;
        padding: 15px;
        position: relative;
    }

    &__head {
        border-bottom: 1px solid $modalCardHeadBorder;
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;
    }

    &__title {
        color: $modalCardTitleColor;
        flex-grow: 1;
        // flex-shrink: 0;
        font-size: $modalCardTitleSize;
        line-height: 1;
        margin: 0;
    }

    &__foot {
        border-bottom-left-radius: 5px;
        border-bottom-right-radius: 5px;
        border-top: 1px solid $modalCardFootBorder;

        > .btn {
            &:not(:last-child) {
                margin-right: 10px;
            }
        }
    }

    &__body {
        background-color: $modalCardBodyBg;
        flex-grow: 1;
        flex-shrink: 1;
        overflow: auto;
        padding: 20px;
    }
}

.ReactModal__Body--open { // sass-lint:disable-line class-name-format
    overflow: hidden;

    .modal {
        display: flex;
    }
}
