//
// Loading
// --------------------------------------------------

// Variables
// ========================================================================

$sloadingBorderSize: 4px;

$sloadingDefaultBorder: rgba(128, 128, 128, .2);
$sloadingDefaultAfterBorder: #808080;

$sloadingFigureSize: 6px;
$sloadingFigureRadius: 2px;

// Ok
$sloadingSuccessBorder: rgba(165, 220, 134, .2);
$sloadingSuccessFinishBorder: #a5dc86;
$sloadingSuccessAfterBg: #a5dc86;
$sloadingSuccessAfterBorder: #a5dc86;

// Error
$sloadingErrorBorder: rgba(242, 116, 116, .2);
$sloadingErrorFinishBorder: #f27474;
$sloadingErrorAfterBg: #f27474;
$sloadingErrorAfterBorder: #f27474;


// Placeholders
// ========================================================================
@keyframes round {
    0% {
        transform: rotate(0);
    }

    100% {
        transform: rotate(360deg);
    }
}

// Component
// ========================================================================
.loading {
    &--abs {
        left: 50%;
        position: absolute;
        top: 50%;
        transform: translate(-50%, -50%);
    }

    &--60 {
        height: 60px;
        width: 60px;
    }
}


// Circle Loading
//
.sloading {
    border: $sloadingBorderSize solid $sloadingDefaultBorder;
    border-radius: 50%;
    box-sizing: content-box;
    height: 100%;
    position: relative;
    width: 100%;

    &::after {
        border: $sloadingBorderSize solid $sloadingDefaultAfterBorder;
        border-radius: 50%;
        box-sizing: content-box;
        content: "";
        height: 100%;
        left: -$sloadingBorderSize;
        position: absolute;
        top: -$sloadingBorderSize;
        width: 100%;
    }

    &__figure {
        align-items: center;
        box-sizing: border-box;
        display: flex;
        height: 100%;
        justify-content: center;
        width: 100%;
    }

    .sfigure__line {
        display: block;
        height: $sloadingFigureSize;
    }

    // Actions
    &.is-animate {
        &::after {
            animation: round 1.3s linear infinite;
            border-bottom-color: transparent;
            border-left-color: transparent;
            border-right-color: transparent;
        }
    }

    &.is-reverse {
        &::after {
            animation-direction: reverse;
        }
    }

    &.is-finish {
        transition: border-color .6s ease-in;

        &.is-success {
            border-color: $sloadingSuccessFinishBorder;
        }

        &.is-error {
            border-color: $sloadingErrorFinishBorder;
        }

        &::after {
            opacity: 0;
        }
    }

    // Styles
    &.is-success {
        border-color: $sloadingSuccessBorder;

        &::after {
            border-color: $sloadingSuccessAfterBorder;
        }
    }

    &.is-error {
        border-color: $sloadingErrorBorder;

        &::after {
            border-color: $sloadingErrorAfterBorder;
        }
    }
}


// Success
//
.sfigure--tick {
    padding: 15%;

    .sfigure__line {
        overflow: hidden;
        position: relative;

        &::after {
            background-color: $sloadingSuccessAfterBg;
            bottom: 0;
            content: "";
            left: -100%;
            position: absolute;
            top: 0;
            width: 100%;
        }

        &--left {
            border-radius: $sloadingFigureRadius 0 0 $sloadingFigureRadius;
            transform: rotate(43deg) translateX(70%);
            width: 40%;
        }

        &--right {
            border-radius: 0 $sloadingFigureRadius $sloadingFigureRadius;
            transform: rotate(-45deg);
            width: 100%;
        }
    }
}

.is-finish.is-success { // sass-lint:disable-line force-element-nesting
    .sfigure--tick {
        .sfigure__line {
            &::after { // sass-lint:disable-line nesting-depth
                left: 0;
                transition: left .2s linear .1s;
            }

            &--left { // sass-lint:disable-line nesting-depth
                &::after {
                    transition-delay: .1s;
                }
            }

            &--right { // sass-lint:disable-line nesting-depth
                &::after {
                    transition-delay: .3s;
                }
            }
        }
    }

    .sfigure--error {
        display: none;
    }
}


// Error
//
.sfigure--error {
    .sfigure__line {
        background-color: $sloadingErrorAfterBg;
        border-radius: $sloadingFigureRadius;
        position: absolute;
        width: 0;

        &--left {
            transform: rotate(45deg);
        }

        &--right {
            transform: rotate(-45deg);
        }
    }
}

.is-finish.is-error { // sass-lint:disable-line force-element-nesting
    .sfigure--error {
        .sfigure__line {
            transition: width .3s ease;
            width: 60%;
        }
    }

    .sfigure--tick {
        display: none;
    }
}
