//
// Bubble
// --------------------------------------------------

// Variables
// ========================================================================
$green: green;
$red: red;
$yellow: yellow;

// Placeholders
// ========================================================================


// Component
// ========================================================================
.bubble {
    border-radius: 50%;
    display: inline-block;
    height: 10px;
    margin: 0 5px;
    vertical-align: middle;
    width: 10px;

    &--success {
        background-color: $green;
    }

    &--error {
        background-color: $red;
    }

    &--warning {
        background-color: $yellow;
    }
}
