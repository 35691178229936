// App
//

$headerBg: #333;
$headerColor: #fff;

$halfColor: #00abc5;
$triColor: #d1db2e;
$mvvColor: #93C01F;

.tos {
    align-items: center;
    display: flex;

    &__text {
        font-size: 12px;
        margin-left: 8px;
    }

    & + & {
        margin-top: 8px;
    }
}

.button.is-grey {
    background-color: #ccc;
    border-color: #ccc;
    color: #4d4d4d;
}

.proteccion-datos {
    p,
    ul {
        font-size: 10px;
        line-height: 1.2;
    }

    h3 {
        font-size: 14px;
        margin-top: 1em !important;
    }
}

.online {
    border-radius: 4px;
    border: 1px solid #fff;
    padding: 5px 8px;
}

.app {
    display: flex;
    flex-direction: column;
    overflow: hidden;
    height: 100%;

    &__header,
    &__footer {
        display: flex;
        flex-direction: column;
        flex-shrink: 0;
        justify-content: flex-start;
    }

    &__header {
        background-color: $headerBg;
        color: $headerColor;
        justify-content: space-between;
        padding: 10px 15px;

        &-logo {
            height: 65px;
            margin-right: 10px;
        }

        &-admin {
            display: block;
            line-height: 1;
        }
    }

    &__wrapper {
        display: flex;
        flex-direction: column;
        height: 90%;
        position: relative;
    }

    &__footer {
        padding: 1.5rem;
        margin-bottom: 0;
        transition: margin-bottom .3s ease;

        &--push {
            margin-bottom: -100%;
        }
    }

    &__main {
        flex-grow: 1;
        flex-shrink: 1;
        overflow: auto;
        box-shadow: inset 0px 11px 8px -10px #CCC, inset 0px -11px 8px -10px #CCC;

        &--inner {
            padding: 1em 1.5em 1.5rem;
        }
    }

    &__title {
        border-bottom: 8px solid transparent;
        font-size: 32px;
        text-align: center;
        text-transform: uppercase;
        margin: 1.5rem;
    }

    // Half
    &--half {
        .app {
            &__title {
                color: $halfColor;
                border-bottom-color: $halfColor;
            }

            &-color {
                color: $halfColor;
            }
        }

        .button--app {
            background-color: $halfColor;
            border-color: $halfColor;
            color: #fff;
        }

        .tos__field {
            border-color: $halfColor;
            position: relative;

            &:checked {
                //background-color: $torrotColor;

                &:before {
                    color: $halfColor;
                }
            }
        }

        .sign {
            border-color: $halfColor;
        }
    }

    // Tri
    &--tri {
        .app {
            &__title {
                color: $triColor;
                border-bottom-color: $triColor;
            }

            &-color {
                color: $triColor;
            }
        }

        .button--app {
            background-color: $triColor;
            border-color: $triColor;
            color: #fff;
        }

        .tos__field {
            border-color: $triColor;
            position: relative;

            &:checked {
                //background-color: $torrotColor;

                &:before {
                    color: $triColor;
                }
            }
        }

        .sign {
            border-color: $triColor;
        }
    }

    // MVV
    &--mvv {
        .app {
            &__title {
                color: $mvvColor;
                border-bottom-color: $mvvColor;
            }

            &-color {
                color: $mvvColor;
            }
        }

        .button--app {
            background-color: $mvvColor;
            border-color: $mvvColor;
            color: #00320F;
        }

        .tos__field {
            border-color: $mvvColor;
            position: relative;

            &:checked {
                //background-color: $torrotColor;

                &:before {
                    color: $mvvColor;
                }
            }
        }

        .sign {
            border-color: $mvvColor;
        }
    }
}
