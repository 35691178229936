$tableHeadRowBorder: grey;
$tableHeadColor: #000;
$tableRowBorder: grey;

$tableRowBg: #ececec;
$tableRowCurrent: rgba(0, 158, 226, 0.65);

.table {
    &__header {
        align-items: center;
        border-bottom: 2px solid $tableHeadRowBorder;
        display: flex;

        .table__col {
            //@extend %bold;
            color: $tableHeadColor;
        }
    }

    &__row {
        align-items: center;
        border-bottom: 1px solid $tableRowBorder;
        display: flex;
        padding-bottom: 5px;
        padding-top: 5px;
    }

    &__col {
        flex-basis: 0;
        flex-grow: 1;
        flex-shrink: 1;
        padding: 5px 10px;
    }

    &__body {
        .table__row {
            flex-flow: row wrap;

            &:nth-child(even) {
                background-color: $tableRowBg;
            }

            &--current {
                background-color: $tableRowCurrent !important;
            }
        }
    }
}
